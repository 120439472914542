const examples = [
  {
    slug: 'jennakutcher',
    title: "What's Your Secret Sauce?",
    id: '5a2fe88a7834f900142fdc68',
    category: 'coaching',
    options: {
      disableCover: true,
    },
  },
  {
    slug: 'amberlilyestrom',
    title: "What's Your Core Purpose?",
    id: '5abea2114964880014be75c9',
    category: 'coaching',
  },
  {
    slug: 'chantizak',
    title: 'What Type of Quiz Should You Make For Your Online Quiz?',
    id: '5b9fef01c7518200138642a2',
    category: 'coaching',
    options: {
      disableCover: true,
    },
  },
  {
    slug: 'erikaholmes',
    title: 'What\'s Your Brand\'s Irresisible "It Factor"?',
    id: '5c0f606f5f0f8d0013312fbe',
    category: 'coaching',
  },
  {
    slug: 'femmessence',
    title: 'What Type of Business or Career Woman Are You?',
    id: '5bd4ceb45cea750013d48e45',
    category: 'coaching',
  },
  {
    slug: 'alexandrahughes',
    title: 'Mama Mantra Quiz',
    id: '5ad590fdfbc3fe0014bfd957',
    category: 'coaching',
  },
  {
    slug: 'screwthecubicle',
    title: 'Are You Ready to Quiz Your 9-5 Routine?',
    id: '5a570ffb291e1200142f14cd',
    category: 'coaching',
  },
  {
    slug: 'cassandrashepard',
    title: 'How To Actually Grow Your Business',
    id: '5b8f34a96df8fe00148f25e0',
    category: 'coaching',
  },
  {
    slug: 'leisapeterson',
    title: 'Are Chakra Imbalances Affecting Your Money Flow?',
    id: '58ea2e6d15a2ba001114fc90',
    category: 'wellness',
  },
  {
    slug: 'habithouse',
    title: 'What type of habit builder are you?',
    id: '5c4f2688d46d5a0014b8a573',
    category: 'wellness',
  },
  {
    slug: 'ellanylea',
    title: 'Overachiever Personality Test',
    id: '5abba5d91901440014c50502',
    category: 'wellness',
  },
  {
    slug: 'tarzankay',
    title: "What's Your 80's Pricing Persona?",
    id: '5abba5d91901440014c50502',
    category: 'copywriting',
  },
  {
    slug: 'thecopywriterclub',
    title: "What's Your Copywriting Superpower?",
    id: '5b69dc968d7c4000144382d1',
    category: 'copywriting',
  },
  {
    slug: 'thelaunchcopywriter',
    title: '​​​​​​​​​​​​​​If your email copy was a movie character, who would it be?',
    id: '5aac226342ee2f00148758ab',
    category: 'copywriting',
    options: {
      disableCover: true,
    },
  },
  {
    slug: 'jeffbullas',
    title: 'What Type Of Digital Marketer Are You?',
    id: '589bd0e49f1c2a0011fb14ec',
    category: 'marketing',
  },
  {
    slug: 'tonicsiteshop',
    title: 'What’s Your Brand Cocktail?',
    id: '5b1b20f11cf276001482ecff',
    category: 'marketing',
    options: {
      disableCover: true,
    },
  },
  {
    slug: 'monicalouie',
    title: 'Is Your Facebook Ads Personality Costing You Money?',
    id: '598cf1f2bed8f50011b69536',
    category: 'marketing',
  },
  {
    slug: 'rickmulready',
    title: 'Become a Facebook Ads Superhero',
    id: '5aaad5335c962b001410a07a',
    category: 'marketing',
  },
  {
    slug: 'dananicoledesigns',
    title: 'Should I Start A Blog?',
    id: '5c52d4860b12ac0014d39977',
    category: 'marketing',
  },
  {
    slug: 'kaylahollatz',
    title: 'What Is Your Brand Voice Style?',
    id: '588a23dbb277160011987103',
    category: 'marketing',
  },
  {
    slug: 'primallypure',
    title: 'Which Primally Pure deodorant scent is the pure-fect fit for your lifestyle?',
    id: '5aa61d5001f6cf00148a86b3',
    category: 'ecommerce',
    options: {
      disableCover: true,
    },
  },
  {
    slug: 'beanbox',
    title: 'What’s Your Coffee Personality?',
    id: '5a66731a5b84a70014e54665',
    category: 'ecommerce',
  },
  {
    slug: 'claires',
    title: 'What’s Your Earring Style?',
    id: '5ade61cfcbb7fe00142cd243',
    category: 'ecommerce',
  },
  {
    slug: 'ilovejeans',
    title: 'What Body Shape Are You?',
    id: '5b3d162ee34ad600148c9499',
    category: 'ecommerce',
  },
  {
    slug: 'peggyhouchinjewelry',
    title: 'What is Your Perfect Gemstone',
    id: '5c1fd6cec25fe60013e972b3',
    category: 'ecommerce',
  },
  {
    slug: 'redcross',
    title: 'Do you actually know how to swim?',
    id: '606c9c49f9ff240017b4f6f1',
    category: 'nonprofit',
  },
];

export default examples;
